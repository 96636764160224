<template>
  <main>
    <div class="horiz-container">
      <h1 class="page-title">{{ pageTitle }}</h1>
      <div class="mentor-list default-grid row-gap">
        <template v-if="items.length === 0 && !pending">
          <div class="no-items">
            <div class="title">У вас пока что еще нет {{ user.is_mentor ? 'менти' : 'менторов' }}</div>
            <PrimaryButton :to="{ name: 'mentor-list' }" v-if="!user.is_mentor">Найти менторов</PrimaryButton>
          </div>
        </template>
        <template v-else-if="user.is_mentor">
          <MentiCard
            v-for="item in items"
            :key="item.id"
            class="item"
            :to="buildRoute(item)"
            :menti="item"
          />
        </template>
        <template v-else>
          <MentorCard
            v-for="item in items"
            :key="item.id"
            class="item"
            :show-button="true"
            :to="buildRoute(item)"
            :mentor="item"
          />
        </template>
      </div>
    </div>
  </main>
</template>

<script setup>
import MentorCard from '@/components/mentor/MentorCard'
import { getMyMenties, getMyMentors } from '@/api/mentoring'
import MentiCard from '@/components/menti/MentiCard'
import PrimaryButton from '@/components/common/PrimaryButton'

const user = inject('user')
const { data: items, pending } = await useAsyncData(() => (user.value.is_mentor ? getMyMenties : getMyMentors)())

const pageTitle = computed(() => `Мои ${user.value.is_mentor ? 'менти' : 'менторы'}`)

useHead({
  title: pageTitle
})

const buildRoute = item => ({ name: 'mentor-lessons', params: { id: item.id } })
</script>

<style scoped lang="scss">
.page-title {
  margin-bottom: 32px;
}

.item {
  grid-column: span 3;
  @media (max-width: 1280px) {
    grid-column: span 4;
  }
  @media (max-width: 980px) {
    grid-column: span 6;
  }
  @media (max-width: 640px) {
    grid-column: span 12;
  }
}
</style>
